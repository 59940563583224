<template>
  <async-container :loading="!loaded">
    <!-- Dados do evento -->
    <v-row>
      <v-col cols="12" lg="6">
        <v-card height="100%">
          <v-card-title class="title-bar">Informações</v-card-title>
          <v-card-text>
            <!-- Titulo -->
            <v-form ref="form-titulo">
              <v-text-field
                label="Título"
                placeholder="Ex: Baile do Colibri"
                v-model="iptTitulo"
                outlined
                :rules="[rules.obrigatorio]"
                dense
                :disabled="saving"
              ></v-text-field>
            </v-form>
            <!-- Descricao -->
            <v-textarea
              label="Descrição"
              placeholder="Insira um texto para a descrição do evento (máx. 700 caracteres)"
              outlined
              rows="4"
              v-model="iptDescricao"
              maxlength="700"
              counter
              :disabled="saving"
              hint="Opcional"
              persistent-hint
            ></v-textarea>
            <!-- Local e endereço -->
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Local"
                  placeholder="Ex: Auditório do CRECI/MS"
                  outlined
                  v-model="iptLocal"
                  dense
                  :disabled="saving"
                  hint="Opcional"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Endereço"
                  placeholder="Ex: Rua Rio Grande do Sul, 174"
                  outlined
                  v-model="iptEndereco"
                  dense
                  :disabled="saving"
                  hint="Opcional"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Taxa -->
            <v-text-field
              label="Taxa"
              placeholder="Ex: 1Kg de alimento não perecível"
              outlined
              persistent-hint
              hint="Deixe vazio caso não haja taxa"
              v-model="iptTaxa"
              dense
              :disabled="saving"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card height="100%">
          <v-card-title class="title-bar">Data</v-card-title>
          <v-card-text>
            <v-form ref="form-datetime">
              <!-- Datetime Inicio -->
              <div>
                <p class="subtitle-2 mb-0" style="line-height: 1">Quando</p>
                <p class="caption mb-0">Obrigatório</p>
                <v-row>
                  <v-col cols="12" md="6" class="pb-md-0">
                    <v-dialog
                      ref="dialog-dateinicio"
                      v-model="dialogDateInicio"
                      :return-value.sync="iptDateInicio"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          placeholder="Clique para escolher a data"
                          :value="txtDateInicio"
                          label="Data do evento"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.obrigatorio]"
                          outlined
                          dense
                          :disabled="saving"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="iptDateInicio"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogDateInicio = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs['dialog-dateinicio'].save(iptDateInicio)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-md-0">
                    <v-dialog
                      ref="dialog-timeinicio"
                      v-model="dialogTimeInicio"
                      :return-value.sync="iptTimeInicio"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          placeholder="Clique para escolher o horário"
                          v-model="iptTimeInicio"
                          label="Hora do evento"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.obrigatorio]"
                          outlined
                          dense
                          :disabled="saving"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="dialogTimeInicio"
                        v-model="iptTimeInicio"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogTimeInicio = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs['dialog-timeinicio'].save(iptTimeInicio)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-divider class="mb-3"></v-divider>
              </div>
              <!-- Datetime Fim -->
              <div>
                <p class="subtitle-2 mb-0" style="line-height: 1">Até quando</p>
                <p class="caption mb-0">Opcional</p>
                <v-row>
                  <v-col cols="12" md="6" class="pb-md-0">
                    <v-dialog
                      ref="dialog-datefim"
                      v-model="dialogDateFim"
                      :return-value.sync="iptDateFim"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          placeholder="Opcional"
                          :value="txtDateFim"
                          label="Data de encerramento"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                          @click:clear="iptDateFim = ''"
                          :disabled="saving"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="iptDateFim"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogDateFim = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs['dialog-datefim'].save(iptDateFim)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-md-0">
                    <v-dialog
                      ref="dialog-timefim"
                      v-model="dialogTimeFim"
                      :return-value.sync="iptTimeFim"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          placeholder="Opcional"
                          v-model="iptTimeFim"
                          label="Hora do encerramento"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                          @click:clear="iptDateFim = ''"
                          :disabled="saving"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="dialogTimeFim"
                        v-model="iptTimeFim"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogTimeFim = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs['dialog-timefim'].save(iptTimeFim)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <!-- Banners -->
        <v-card>
          <v-card-title class="title-bar">Banners</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <v-card outlined>
                  <v-card-title class="body-2 pa-3" style="border-bottom: 1px solid #dbdbdb">Imagem do slide</v-card-title>
                  <v-card-text style="min-height: 300px" class="d-flex justify-center align-center pa-3">
                    <img v-if="imagemSrc" :src="imagemSrc" alt="" style="max-width: 100%" class="elevation-2">
                    <v-btn v-else color="primary" @click="$refs.uploader.click()">Inserir imagem</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card outlined>
                  <v-card-title class="body-2 pa-3" style="border-bottom: 1px solid #dbdbdb">Imagem da página de detalhes</v-card-title>
                  <v-card-text style="min-height: 300px" class="d-flex justify-center align-center pa-3">
                    <img v-if="capaSrc" :src="capaSrc" alt="" style="max-width: 100%" class="elevation-2">
                    <v-btn v-else color="primary" @click="$refs.uploadercapa.click()">Inserir imagem</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <!-- Sistema de inscrição -->
        <v-card>
          <div class="title-bar d-flex justify-space-between align-center white">
            <div>
              <v-card-title class="px-0 pt-0">Sistema de inscrições</v-card-title>
              <v-card-subtitle class="px-0 pb-0">Fornece uma plataforma de inscrição para o evento</v-card-subtitle>
            </div>
            <v-switch v-model="iptInscricao" hide-details inset class="mt-0"></v-switch>
          </div>
          <v-card-text>
            <div v-if="iptInscricao">
              <v-form ref="form-inscricao">
                <v-text-field
                  label="Vagas"
                  placeholder="Digite o número de vagas"
                  persistent-hint
                  hint="0 = Ilimitado"
                  type="number"
                  outlined
                  dense
                  v-model="iptVagas"
                  style="max-width: 16rem"
                  :disabled="saving"
                  :rules="[rules.obrigatorio, rules.numerico]"
                ></v-text-field>
              </v-form>
              <v-switch
                label="Somente corretores de imóveis"
                persistent-hint
                hint="Exigirá o nº de CRECI no ato da inscrição"
                v-model="iptSomenteCorretores"
                :disabled="saving"
              ></v-switch>
              <v-scroll-x-transition>
                <v-switch
                  v-if="iptSomenteCorretores"
                  label="Somente aptos a certidão"
                  persistent-hint
                  hint="Corretores inaptos não poderão se inscrever"
                  v-model="iptSomenteAptos"
                  :disabled="saving"
                ></v-switch>
              </v-scroll-x-transition>
            </div>
            <p v-else class="text-center body-1">Sistema de inscrições desabilitado</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Botões de ação -->
    <div class="text-center">
      <v-btn color="success" class="mr-2" @click="salvar">SALVAR</v-btn>
      <v-btn color="secondary" to="/sitecreci/eventos">CANCELAR</v-btn>
    </div>

    <!-- Cropper -->
    <v-dialog
      v-model="dialogCropper"
      width="400"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <div class="d-flex">
          <div>
            <v-card-title>Ajuste a imagem</v-card-title>
            <v-card-subtitle>Arraste a imagem e regule o zoom</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCropper = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="px-2">
          <vue-cropper
            ref="cropper"
            :src="cropperSrc"
            :aspectRatio="360/270"
            :cropBoxMovable="true"
            :viewMode="1"
            :dragMode="'move'"
          ></vue-cropper>
        </div>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="substituirImagem">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      ref="uploader"
      style="display: none"
      type="file"
      accept="image/jpeg,image/png"
      @change="onFileChange"
    >

    <!-- Cropper Capa -->
    <v-dialog
      v-model="dialogCropperCapa"
      width="400"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <div class="d-flex">
          <div>
            <v-card-title>Ajuste a imagem</v-card-title>
            <v-card-subtitle>Arraste a imagem e regule o zoom</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCropperCapa = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="px-2">
          <vue-cropper
            ref="croppercapa"
            :src="cropperCapaSrc"
            :aspectRatio="1138/614"
            :cropBoxMovable="true"
            :viewMode="1"
            :dragMode="'move'"
          ></vue-cropper>
        </div>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="substituirImagemCapa">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      ref="uploadercapa"
      style="display: none"
      type="file"
      accept="image/jpeg,image/png"
      @change="onFileChangeCapa"
    >
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import moment from 'moment';
import ImageHelper from '@/helpers/ImageHelper';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import SiteWebClient from '@/http/SiteWebClient';
import StringHelper from '@/helpers/StringHelper';
export default {
  name: 'EventoEditor',
  props: ['id'],
  components: {AsyncContainer, VueCropper},
  data: () => ({
    loaded: false,
    iptTitulo: '',
    iptDescricao: '',
    iptLocal: '',
    iptEndereco: '',
    iptTaxa: '',
    iptDateInicio: '',
    iptTimeInicio: '',
    iptDateFim: '',
    iptTimeFim: '',
    iptImagem: null,  //Imagem do carousel
    iptCapa: null,    //Imagem da página de detalhes
    dialogDateInicio: false,
    dialogTimeInicio: false,
    dialogDateFim: false,
    dialogTimeFim: false,
    rules: {
      obrigatorio: v => !!v || 'Este campo é obrigatório',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números',
    },
    saving: false,
    dialogCropper: false,
    dialogCropperCapa: false,
    iptCropperBlob: null,
    iptCropperCapaBlob: null,
    fileModeCapa: false, //sem uso ainda, é pra diferenciar se a imagem é pra carousel ou capa
    //sistema de inscricao
    iptInscricao: false,
    iptVagas: '',
    iptSomenteCorretores: false,
    iptSomenteAptos: false,
    webClient: new SiteWebClient(),
  }),
  computed: {
    txtDateInicio() {
      return this.iptDateInicio ? moment(this.iptDateInicio).format('DD/MM/YYYY') : '';
    },
    txtDateFim() {
      return this.iptDateFim ? moment(this.iptDateFim).format('DD/MM/YYYY') : '';
    },
    cropperSrc() {
      return this.iptCropperBlob ? window.URL.createObjectURL(this.iptCropperBlob) : null;
    },
    imagemSrc() {
      return this.iptImagem ? window.URL.createObjectURL(this.iptImagem) : null;
    },
    cropperCapaSrc() {
      return this.iptCropperCapaBlob ? window.URL.createObjectURL(this.iptCropperCapaBlob) : null;
    },
    capaSrc() {
      return this.iptCapa ? window.URL.createObjectURL(this.iptCapa) : null;
    },
  },
  methods: {
    async loadData() {
      try {
        const evento = await this.webClient.getEvento(this.id);
        this.iptTitulo = evento.titulo;
        this.iptDescricao = evento.descricao ? evento.descricao : '';
        this.iptLocal = evento.local ? evento.local : '';
        this.iptEndereco = evento.endereco ? evento.endereco : '';
        this.iptTaxa = evento.taxa ? evento.taxa : '';
        console.log(evento);
      } catch (e) {
        this.$router.push('/sitecreci/eventos');
      } finally {
        this.loaded = true;
      }
    },
    async onFileChange(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      this.iptCropperBlob = new Blob([file], {type: file.type});
      if (this.$refs.cropper) this.$refs.cropper.replace(this.cropperSrc);
      this.dialogCropper = true;
      this.$refs.uploader.value = '';
    },
    async onFileChangeCapa(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      this.iptCropperCapaBlob = new Blob([file], {type: file.type});
      if (this.$refs.croppercapa) this.$refs.croppercapa.replace(this.cropperCapaSrc);
      this.dialogCropperCapa = true;
      this.$refs.uploadercapa.value = '';
    },
    substituirImagem() {
      const canvas = this.$refs.cropper.getCroppedCanvas({
        height: 360,
        width: 270,
        fillColor: '#ffffff',
      });
      const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .88);
      this.iptImagem = ImageHelper.dataURItoBlob(base64);
      this.dialogCropper = false;
      this.iptCropperBlob = null;
    },
    substituirImagemCapa() {
      const canvas = this.$refs.croppercapa.getCroppedCanvas({
        height: 1138,
        width: 614,
        fillColor: '#ffffff',
      });
      const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .88);
      this.iptCapa = ImageHelper.dataURItoBlob(base64);
      this.dialogCropperCapa = false;
      this.iptCropperCapaBlob = null;
    },
    async salvar() {
      let valido = true;
      if (!this.$refs['form-titulo'].validate()) {
        this.$alert({text: 'Insira o título', type: 'error'});
        valido = false;
      }
      if (!this.$refs['form-datetime'].validate()) {
        this.$alert({text: 'Insira a data', type: 'error'});
        valido = false;
      }
      if (this.iptInscricao && !this.$refs['form-inscricao'].validate()) {
        this.$alert({text: 'Se deseja usar o sistema de inscrição, insira o número de vagas', type: 'error'});
        valido = false;
      }
      if (!valido) return;

      if (!this.iptImagem || !this.iptCapa) {
        this.$alert({text: 'É necessário ter as imagens para divulgação', type: 'error'});
        return;
      }
      if ((this.iptDateFim && !this.iptTimeFim) || (!this.iptDateFim && this.iptTimeFim)) {
        this.$alert({text: 'Se deseja utilizar a data de encerramento, você precisa preencher ambos (data e hora), ou então deixe-os vazio', type: 'warning'});
        return;
      }
      this.saving = true;
      try {
        const dateInicio = this.iptDateInicio + ' ' + this.iptTimeInicio + ':00';
        const dateFim = (this.iptDateFim && this.iptTimeFim) ? this.iptDateFim + ' ' + this.iptTimeFim + ':00' : null;
        if (this.id) {
          await this.webClient.updateEvento(this.id, this.iptTitulo, this.iptImagem, this.iptCapa, dateInicio, dateFim, this.iptDescricao, this.iptLocal, this.iptEndereco, this.iptTaxa, this.iptVagas, this.iptSomenteCorretores, this.iptSomenteAptos);
          this.$snackbar({text: 'Evento atualizado', color: 'success'});
        }
        else {
          await this.webClient.insertEvento(this.iptTitulo, this.iptImagem, this.iptCapa, dateInicio, dateFim, this.iptDescricao, this.iptLocal, this.iptEndereco, this.iptTaxa, this.iptVagas, this.iptSomenteCorretores, this.iptSomenteAptos);
          this.$snackbar({text: 'Evento registrado', color: 'success'});
          this.$router.push('/sitecreci/eventos');
        }
      } finally {
        this.saving = false;
      }
    },
  },
  async created() {
    try {
      if (this.id) await this.loadData();
      else this.loaded = true;
    } catch (e) {
      this.$router.push('/sitecreci/eventos');
    }
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 1rem;
  }
</style>
