var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('async-container',{attrs:{"loading":_vm.loadingContainer}},[_c('p',{staticClass:"caption mb-0"},[_vm._v("Site do CRECI")]),_c('p',{staticClass:"title"},[_vm._v("Comunicados")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":"inicio","sort-desc":"","footer-props":{'items-per-page-options': [10, 25, 50, 100]}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_vm._v(" Programação "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/sitecreci/comunicado"}},[_vm._v("ADICIONAR")])],1)]},proxy:true},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [(item.ativo)?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v("NO AR")]):(item.inicio > _vm.now)?_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v("NA FILA")]):_c('v-chip',{attrs:{"small":"","color":"secondary"}},[_vm._v("EXPIRADO")])]}},{key:"item.inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatarData(item.inicio)))]}},{key:"item.fim",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatarData(item.fim)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"icon":"","x-small":"","to":'/sitecreci/comunicado/' + item.id,"color":"warning"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deletarComunicado(item.id, item.titulo)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])}),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#f5f5f5","border-bottom":"1px solid #dbdbdb"}},[_vm._v("Excluir comunicado")]),_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"48","color":"error"}},[_vm._v("mdi-alert")]),_c('p',{staticClass:"body-1 flex-grow-1 mb-0"},[_vm._v("Você está prestes a apagar:"),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("\""+_vm._s(_vm.deleteTitle)+"\"")])])],1)]),_c('v-card-actions',{staticStyle:{"background-color":"#f5f5f5","border-top":"1px solid #dbdbdb"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.loading},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v("CANCELAR")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loading},on:{"click":function($event){return _vm.deletarComunicado()}}},[_vm._v("CONFIRMAR")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }