<template>
  <async-container :loading="loading">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Texto Institucional</p>
    <Editor v-model="html" :init="editorConfig"/>
    <div class="text-center mt-3">
      <v-btn color="primary" @click="saveText" :loading="saving">SALVAR</v-btn>
    </div>
  </async-container>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue'
  import {loadImageAsync} from '@/plugins/loadImage';
  import AsyncContainer from "@/components/interface/AsyncContainer";
  import TextWebClient from "@/http/TextWebClient";
  export default {
    name: "TextoInstitucional",
    components: {AsyncContainer, Editor},
    data: () => ({
      loading: true,
      saving: false,
      html: '',
      editorConfig: {
        language: 'pt_BR',
        plugins: 'code table link lists fullscreen print preview autoresize image',
        menubar: 'edit view table',
        toolbar: 'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link image | removeformat print', // fontselect
        contextmenu: 'link fullscreen',
        indentation: '2em',
        mobile: {
          theme: 'mobile',
          plugins: ['lists'],
          toolbar: ['undo', 'redo', 'bold', 'italic', 'fontsizeselect', 'underline', 'bullist']
        },
        branding: false,
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            const blob = blobInfo.blob();
            const canvas = await loadImageAsync(blob, {
              maxWidth: 1280,
              maxHeight: 720,
              canvas: true,
              cover: false,
              orientation: true,
            });
            if (blob.type === 'image/png') success(canvas.toDataURL('image/png'));
            else success(canvas.toDataURL('image/jpeg', .88));
          } catch (e) {
            failure()
          }
        },
      },
    }),
    methods: {
      async loadText() {
        const webClient = new TextWebClient();
        try {
          this.html = await webClient.getText('institucional');
        } finally {
          this.loading = false;
        }
      },
      async saveText() {
        this.saving = true;
        const webClient = new TextWebClient();
        try {
          await webClient.setText('institucional', this.html);
          this.$snackbar({text: 'Texto salvo', color: 'success'});
        } finally {
          this.saving = false;
        }
      },
    },
    created() {
      this.loadText();
    },
  }
</script>

<style scoped>

</style>