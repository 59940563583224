import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class TextWebClient {
  http = httpSnackbar

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem um texto
   * @param {string} name
   * @returns {Promise<string>}
   */
  async getText(name) {
    const {data} = await this.http.get('/text', {params: {id: name}});
    return data;
  }

  /**
   * Grava ou atualiza um texto
   * @param {string} name
   * @param {string} text
   * @returns {Promise<void>}
   */
  async setText(name, text) {
    const formData = new FormData();
    formData.append('id', name);
    formData.append('text', text);
    await this.http.post('/text', formData);
  }
}
