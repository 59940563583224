var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('async-container',{staticStyle:{"max-width":"900px"},attrs:{"loading":_vm.firstLoad}},[_c('p',{staticClass:"caption mb-0"},[_vm._v("Site do CRECI")]),_c('p',{staticClass:"title"},[_vm._v("Banners de destaque")]),_c('v-card',{staticClass:"mb-5 mx-auto"},[_c('v-card-title',{staticStyle:{"background-color":"#f5f5f5","border-bottom":"1px solid #dbdbdb"}},[_vm._v("Pré-visualização")]),_c('v-carousel',{attrs:{"height":"auto"}},_vm._l((_vm.items),function(i){return _c('v-carousel-item',{key:i.id,attrs:{"href":i.link ? i.link : undefined,"target":"_blank"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.getBlobTempUrl(i.image),"alt":""}}),_c('div',{staticClass:"legenda pb-3"},[_c('p',{staticClass:"title mb-0"},[_vm._v(_vm._s(i.titulo))]),_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(i.legenda))])])])}),1)],1),_c('v-data-table',{staticClass:"elevation-2 mb-5",attrs:{"items":_vm.items,"headers":_vm.headers,"sort-by":"ordem","loading":_vm.loading,"items-per-page":-1,"footer-props":{'items-per-page-options': [10, 25, 50, 100, -1]},"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{staticStyle:{"background-color":"#f5f5f5","border-bottom":"1px solid #dbdbdb"}},[_vm._v(" Configuração "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/sitecreci/destaque"}},[_vm._v("ADICIONAR")])],1)]},proxy:true},{key:"item.imagem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.image)?_c('v-img',{staticStyle:{"border-radius":".35rem"},attrs:{"src":_vm.getBlobTempUrl(item.image),"max-width":"5rem","max-height":"3rem"}}):_vm._e()],1)]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"subtitle-1 mb-0 text-decoration-none d-block",attrs:{"to":'/sitecreci/destaque/' + item.id}},[_vm._v("Destaque "+_vm._s(item.ordem + 1))]),_c('v-divider',{staticStyle:{"max-width":"6rem"}}),(item.titulo)?_c('p',{staticClass:"subtitle-2 mb-0"},[_vm._v(_vm._s(item.titulo))]):_vm._e(),(item.link)?_c('a',{staticClass:"caption mb-0 text-decoration-none",attrs:{"href":item.link,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-link")]),_vm._v(" "+_vm._s(item.link)+" ")],1):_vm._e()]}},{key:"item.ordem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"icon":"","disabled":item.id === _vm.primeiroItemId},on:{"click":function($event){return _vm.alterarOrdem(item.id, item.ordem - 1)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"icon":"","disabled":item.id === _vm.ultimoItemId},on:{"click":function($event){return _vm.alterarOrdem(item.id, item.ordem + 1)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"icon":"","color":"warning","to":'/sitecreci/destaque/' + item.id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteDestaque(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])}),_c('v-scale-transition',[(_vm.ordemAlterada)?_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.updateDestaques}},[_vm._v("SALVAR")]),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.loading},on:{"click":_vm.loadData}},[_vm._v("CANCELAR")])],1):_vm._e()]),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',{attrs:{"loading":_vm.deleting}},[_c('v-card-title',{staticStyle:{"background-color":"#f5f5f5","border-bottom":"1px solid #dbdbdb"}},[_vm._v("Excluir destaque")]),_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"48","color":"error"}},[_vm._v("mdi-alert")]),_c('p',{staticClass:"body-1 flex-grow-1 mb-0"},[_vm._v("Tem certeza que deseja apagar o banner?")])],1)]),_c('v-card-actions',{staticStyle:{"background-color":"#f5f5f5","border-top":"1px solid #dbdbdb"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.deleting},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v("CANCELAR")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting},on:{"click":function($event){return _vm.deleteDestaque()}}},[_vm._v("CONFIRMAR")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }