<template>
  <async-container :loading="loadingContainer">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Comunicado</p>
    <v-form ref="form-titulo">
      <v-text-field placeholder="Insira o título aqui" solo v-model="iptTitulo" :rules="[rules.obrigatorio]"></v-text-field>
    </v-form>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card style="height: 100%">
          <v-card-title class="py-3 card-title">
            Imagem
            <v-spacer></v-spacer>
            <v-btn v-if="imagemSrc" color="primary" @click="$refs.uploader.click()" small>Alterar</v-btn>
          </v-card-title>
          <v-card-text class="text-center">
            <img v-if="imagemSrc" :src="imagemSrc" style="max-width: 100%" class="elevation-3 mt-3" alt=""/>
            <div v-else class="mt-3">
              <p class="mb-1">Nenhuma imagem inserida</p>
              <v-btn color="primary" rounded @click="$refs.uploader.click()">Inserir</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card style="height: 100%">
          <v-card-title class="py-3 card-title">Texto</v-card-title>
          <v-card-text class="pt-3">
            <v-textarea outlined hide-details v-model="iptTexto"></v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <v-card-title class="py-3 card-title">Ajustes</v-card-title>
      <v-card-text>
        <!-- Largura do container -->
        <div>
          <p class="body mb-0 mt-2">
            <v-icon>mdi-arrow-split-vertical</v-icon> Largura máxima do container
          </p>
          <v-radio-group v-model="iptLargura" row class="mt-1">
            <v-radio label="Pequeno" :value="0"></v-radio>
            <v-radio label="Médio" :value="1"></v-radio>
            <v-radio label="Grande" :value="2"></v-radio>
            <v-radio label="Máximo" :value="3"></v-radio>
          </v-radio-group>
        </div>
        <v-divider></v-divider>
        <!-- Botão do container -->
        <div>
          <v-switch label="Usar um botão com link" v-model="iptUsarBotaoLink"></v-switch>
          <v-scroll-x-transition>
            <div v-if="iptUsarBotaoLink">
              <v-text-field label="Link do botão" v-model="iptLink" :rules="[rules.obrigatorio]" outlined></v-text-field>
              <v-text-field label="Texto do botão" v-model="iptBotao" :rules="[rules.obrigatorio]" outlined></v-text-field>
            </div>
          </v-scroll-x-transition>
        </div>
        <v-divider></v-divider>
        <!-- Data de publicação -->
        <div>
          <p class="body mb-0 mt-3">Data e hora da divulgação</p>
          <p class="caption mb-0">Ajusta quando o comunicado irá ao ar</p>
          <v-row>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-dateinicio"
                v-model="dialogDateInicio"
                :return-value.sync="iptDateInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form ref="form-dateinicio">
                    <v-text-field
                      :value="txtDateInicio"
                      label="Data da divulgação"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                    ></v-text-field>
                  </v-form>
                </template>
                <v-date-picker
                  v-model="iptDateInicio"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogDateInicio = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-dateinicio'].save(iptDateInicio)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-timeinicio"
                v-model="dialogTimeInicio"
                :return-value.sync="iptTimeInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form ref="form-timeinicio">
                    <v-text-field
                      v-model="iptTimeInicio"
                      label="Hora da divulgação"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                    ></v-text-field>
                  </v-form>
                </template>
                <v-time-picker
                  v-if="dialogTimeInicio"
                  v-model="iptTimeInicio"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogTimeInicio = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-timeinicio'].save(iptTimeInicio)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- Data de remoção -->
        <div>
          <p class="body mb-0 mt-3">Data e hora da remoção</p>
          <p class="caption mb-0">Ajusta quando o comunicado sairá do ar</p>
          <v-row>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-datefim"
                v-model="dialogDateFim"
                :return-value.sync="iptDateFim"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form ref="form-datefim">
                    <v-text-field
                      :value="txtDateFim"
                      label="Data da remoção"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                    ></v-text-field>
                  </v-form>
                </template>
                <v-date-picker
                  v-model="iptDateFim"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogDateFim = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-datefim'].save(iptDateFim)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-timefim"
                v-model="dialogTimeFim"
                :return-value.sync="iptTimeFim"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form ref="form-timefim">
                    <v-text-field
                      v-model="iptTimeFim"
                      label="Hora da remoção"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                      outlined
                    ></v-text-field>
                  </v-form>
                </template>
                <v-time-picker
                  v-if="dialogTimeFim"
                  v-model="iptTimeFim"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogTimeFim = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-timefim'].save(iptTimeFim)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="my-3 text-center">
      <v-btn color="info" outlined @click="showPreview = true">Pré-Visualizar</v-btn>
      <v-btn color="primary" @click="salvar" :loading="saving" class="mx-2">Salvar</v-btn>
      <v-btn color="secondary" to="/sitecreci/comunicados" :disabled="saving">Cancelar</v-btn>
    </div>
    <input
      ref="uploader"
      style="display: none"
      type="file"
      accept="image/jpeg,image/png"
      @change="onFileChange"
    >
    <v-dialog v-model="showPreview" :max-width="modalMaxWidth" scrollable>
      <v-card>
        <v-card-title class="info white--text py-2 px-3">
          <div class="d-flex align-center">
            <v-icon class="mr-2" color="white">mdi-bell-ring</v-icon> Comunidado
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="showPreview = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0" style="max-height: 90%">
          <img v-if="imagemSrc" :src="imagemSrc" alt="" style="width: 100%; height: auto">
          <div class="pa-2" v-if="iptTexto">
            <p style="white-space: pre-wrap; text-align: justify" class="mb-0">{{iptTexto}}</p>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center" style="border-top: 1px solid #dbdbdb">
          <v-btn v-if="iptUsarBotaoLink" :href="iptLink" target="_blank" class="mr-2" color="primary">{{iptBotao}}</v-btn>
          <v-btn color="primary" @click="showPreview = false" :outlined="iptUsarBotaoLink">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import moment from 'moment';
import ImageHelper from "@/helpers/ImageHelper";
export default {
  name: 'ComunicadoEditor',
  props: ['id'],
  components: {AsyncContainer},
  data: () => ({
    loadingContainer: true,
    iptTitulo: '',
    iptTexto: '',
    iptLargura: 1,
    iptUsarBotaoLink: false,
    iptBotao: '',
    iptLink: '',
    iptDateInicio: '',
    iptTimeInicio: '',
    iptDateFim: '',
    iptTimeFim: '',
    iptImagem: null, //Blob
    saving: false,
    rules: {
      obrigatorio: v => !!v || 'Este campo é obrigatório',
    },
    dialogDateInicio: false,
    dialogTimeInicio: false,
    dialogDateFim: false,
    dialogTimeFim: false,
    showPreview: false,
  }),
  methods: {
    async loadData() {
      if (!this.id) return;
      const webClient = new SiteWebClient();
      try {
        const comunicado = await webClient.getComunicado(this.id);
        this.iptTitulo = comunicado.titulo;
        this.iptTexto = comunicado.texto;
        this.iptLargura = comunicado.largura;
        this.iptUsarBotaoLink = comunicado.botao && comunicado.link;
        this.iptBotao = comunicado.botao ? comunicado.botao : '';
        this.iptLink = comunicado.link ? comunicado.link : '';
        this.iptDateInicio = comunicado.inicio.substr(0, 10);
        this.iptTimeInicio = comunicado.inicio.substr(11, 5);
        this.iptDateFim = comunicado.fim.substr(0, 10);
        this.iptTimeFim = comunicado.fim.substr(11, 5);
        this.iptImagem = comunicado.imagem;
      } catch (e) {
        this.$router.push('/sitecreci/comunicados');
      } finally {
        this.loadingContainer = false;
      }
    },
    async salvar() {
      let valido = true;
      if (!this.$refs['form-titulo'].validate()) {valido = false; this.$alert({text: 'Arrume a data da divulgação', type: 'error'})}
      if (!this.$refs['form-dateinicio'].validate() || !this.$refs['form-timeinicio'].validate()) {valido = false; this.$alert({text: 'Arrume a data da divulgação', type: 'error'})}
      if (!this.$refs['form-datefim'].validate() || !this.$refs['form-timefim'].validate()) {valido = false; this.$alert({text: 'Arrume a data da remoção', type: 'error'})}
      if (!valido) return;

      const webClient = new SiteWebClient();
      this.saving = true;
      try {
        const datetime_inicio = this.iptDateInicio + ' ' + this.iptTimeInicio + ':00';
        const datetime_fim = this.iptDateFim + ' ' + this.iptTimeFim + ':00';
        if (this.id) {
          await webClient.updateComunicado(this.id, this.iptTitulo, this.iptLargura, datetime_inicio, datetime_fim, this.iptImagem, this.iptTexto, this.iptUsarBotaoLink ? this.iptBotao : '', this.iptUsarBotaoLink ? this.iptLink : '');
          this.$snackbar({text: 'Comunicado atualizado', color: 'success'});
        } else {
          await webClient.insertComunicado(this.iptTitulo, this.iptLargura, datetime_inicio, datetime_fim, this.iptImagem, this.iptTexto, this.iptUsarBotaoLink ? this.iptBotao : '', this.iptUsarBotaoLink ? this.iptLink : '');
          this.$router.push('/sitecreci/comunicados');
        }
      } finally {
        this.saving = false;
      }
    },
    async onFileChange(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      const canvas = await this.$loadImageAsync(file, {
        maxWidth: 1140,
        maxHeight: 900,
        orientation: true,
        canvas: true
      });
      this.iptImagem = ImageHelper.dataURItoBlob(canvas.toDataURL('image/jpeg', 0.94));
      this.$refs.uploader.value = '';
    },
  },
  created() {
    if (this.id) this.loadData();
    else {
      this.iptDateInicio = moment().format('YYYY-MM-DD');
      this.iptTimeInicio = moment().format('HH:mm');
      this.loadingContainer = false;
    }
  },
  computed: {
    txtDateInicio() {
      return this.iptDateInicio ? moment(this.iptDateInicio).format('DD/MM/YYYY') : '';
    },
    txtDateFim() {
      return this.iptDateFim ? moment(this.iptDateFim).format('DD/MM/YYYY') : '';
    },
    imagemSrc() {
      return this.iptImagem ? window.URL.createObjectURL(this.iptImagem) : null;
    },
    modalMaxWidth() {
      switch (this.iptLargura) {
        case 0:
          return '300';
        case 1:
          return '500';
        case 2:
          return '800';
        case 3:
          return '1140';
        default:
          return undefined;
      }
    },
  },
}
</script>

<style scoped>
  .card-title {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb;
  }
</style>