<template>
  <async-container :loading="loading" :text="loadingText">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">{{id ? `Noticia ${id}` : 'Nova notícia'}}</p>
    <!-- Titulo e corpo -->
    <v-card class="mb-5" elevation="4">
      <v-card-title>Redação</v-card-title>
      <v-card-subtitle>Defina o título e corpo da publicação</v-card-subtitle>
      <v-divider/>
      <v-card-text>
        <v-form v-model="formsValidation.titulo">
          <v-text-field
            label="Título"
            v-model="iptTitulo"
            outlined
            :rules="[rules.obrigatorio]"
          ></v-text-field>
        </v-form>
        <Editor v-model="iptTexto" :init="editorConfig"/>
      </v-card-text>
    </v-card>
    <!-- Imagem de capa e miniatura -->
    <v-card class="mb-5" elevation="4">
      <v-card-title>Imagem de capa</v-card-title>
      <v-card-subtitle>Representação visual da notícia</v-card-subtitle>
      <v-divider/>
      <v-card-text>
        <div v-if="iptCapa">
          <v-row>
            <v-col cols="12" lg="9">
              <v-card outlined>
                <v-card-subtitle class="py-2">Capa da notícia</v-card-subtitle>
                <v-divider/>
                <v-card-text class="text-center pb-0">
                  <img :src="capaSrc" style="max-width: 100%" class="elevation-3" alt=""/>
                </v-card-text>
                <v-card-actions class="justify-center pt-0">
                  <v-btn class="mt-2" color="primary" small rounded @click="$refs.uploader.click()">
                    <v-icon>mdi-refresh</v-icon>Alterar capa
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-card-subtitle class="py-2">Miniatura da notícia</v-card-subtitle>
                <v-divider/>
                <v-card-text class="text-center pb-0">
                  <v-avatar size="96" class="elevation-3">
                    <img :src="miniaturaSrc" alt="capa"/>
                  </v-avatar>
                </v-card-text>
                <v-card-actions class="justify-center pt-0">
                  <v-btn class="mt-2" color="primary" small @click="evocarCropperMiniatura">
                    <v-icon>mdi-cursor-move</v-icon>Ajustar miniatura
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="text-center" v-else>
          <p class="caption">Nenhuma imagem inserida</p>
          <v-btn color="primary" @click="$refs.uploader.click()">INSERIR IMAGEM</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <!-- Data de publicação e remoção -->
    <v-card class="mb-5" elevation="4">
      <v-card-title>Programação</v-card-title>
      <v-card-subtitle>Publique imediatamente ou em data programada</v-card-subtitle>
      <v-divider/>
      <v-card-text>
        <div>
          <p class="subtitle-1" v-if="usarRemocao">Data de publicação</p>
          <v-row>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-dateinicio"
                v-model="dialogDateInicio"
                :return-value.sync="iptDataInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form v-model="formsValidation.dataInicio">
                    <v-text-field
                      v-model="iptDataInicio"
                      label="Data da divulgação"
                      prepend-icon="mdi-calendar"
                      hint="ano-mês-dia"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                    ></v-text-field>
                  </v-form>
                </template>
                <v-date-picker
                  v-model="iptDataInicio"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogDateInicio = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-dateinicio'].save(iptDataInicio)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6">
              <v-dialog
                ref="dialog-timeinicio"
                v-model="dialogTimeInicio"
                :return-value.sync="iptTimeInicio"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-form v-model="formsValidation.horaInicio">
                    <v-text-field
                      v-model="iptTimeInicio"
                      label="Hora da divulgação"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.obrigatorio]"
                    ></v-text-field>
                  </v-form>
                </template>
                <v-time-picker
                  v-if="dialogTimeInicio"
                  v-model="iptTimeInicio"
                  full-width
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="dialogTimeInicio = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['dialog-timeinicio'].save(iptTimeInicio)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-scale-transition mode="out-in">
          <v-btn color="warning" block @click="usarRemocao = true" v-if="!usarRemocao">PROGRAMAR REMOÇÃO AUTOMÁTICA</v-btn>
          <div v-else class="mt-3">
            <p class="subtitle-1 mb-0">Data de remoção</p>
            <v-row>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="dialog-datefim"
                  v-model="dialogDateFim"
                  :return-value.sync="iptDataFim"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-form v-model="formsValidation.dataFim">
                      <v-text-field
                        v-model="iptDataFim"
                        label="Data da remoção"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </v-form>
                  </template>
                  <v-date-picker
                    v-model="iptDataFim"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogDateFim = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-datefim'].save(iptDataFim)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="dialog-timefim"
                  v-model="dialogTimeFim"
                  :return-value.sync="iptTimeFim"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-form v-model="formsValidation.horaFim">
                      <v-text-field
                        v-model="iptTimeFim"
                        label="Hora da divulgação"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </v-form>
                  </template>
                  <v-time-picker
                    v-if="dialogTimeFim"
                    v-model="iptTimeFim"
                    full-width
                    format="24hr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dialogTimeFim = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs['dialog-timefim'].save(iptTimeFim)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn color="error" rounded small @click="usarRemocao = false">
                <v-icon>mdi-close</v-icon>NÃO USAR REMOÇÃO PROGRAMADA
              </v-btn>
            </div>
          </div>
        </v-scale-transition>
      </v-card-text>
    </v-card>
    <!-- Galeria de imagens -->
    <v-card class="mb-5" elevation="4" v-if="noticia">
      <v-card-title>
        Álbum de fotos
        <v-spacer/>
        <v-btn small color="success" fab @click="$refs['galery-upload'].click()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-container>
        <!-- Imagens atuais -->
        <div class="text-center" v-if="noticia.fotos.length">
          <div class="d-flex justify-space-around flex-wrap">
            <v-card v-for="(f, index) in noticia.fotos" :key="f.id" width="200" outlined class="my-1">
              <v-img height="200px" :src="getBlobTempUrl(f.blob)" contain class="blue-grey lighten-2 align-end">
                <div class="text-center">
                  <v-btn
                    color="error"
                    fab
                    small
                    class="mb-1"
                    @click="deleteImagem(f.id)"
                    :loading="loadingDeleteImgBtn === index"
                    :disabled="loadingDeleteImgBtn !== null && loadingDeleteImgBtn !== index"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </v-card>
          </div>
        </div>
        <!-- Nenhuma imagem -->
        <div class="text-center" v-else-if="galeria.length === 0">
          <p>Nenhuma imagem na galeria</p>
          <v-btn color="success" small @click="$refs['galery-upload'].click()">Inserir imagem</v-btn>
        </div>
        <!-- Novas imagens -->
        <div v-if="galeria.length">
          <v-divider v-if="noticia.fotos.length" class="mt-4"></v-divider>
          <v-subheader>Novas</v-subheader>
          <div class="d-flex justify-space-around flex-wrap">
            <v-card v-for="g in galeria.map((v, i) => ({index: i, blob: v}))" :key="g.index" width="200" outlined class="my-1">
              <v-img height="200px" :src="getBlobTempUrl(g.blob)" contain class="blue-grey lighten-2 align-end">
                <div class="text-center">
                  <v-btn color="error" fab small class="mb-1" @click="galeria.splice(g.index, 1)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </v-card>
          </div>
        </div>
      </v-container>
    </v-card>
    <!-- Ações -->
    <div class="text-center mb-5">
      <v-btn color="primary" class="mr-2" @click="salvarNoticia" :loading="savingData">SALVAR</v-btn>
      <v-btn color="secondary" to="/sitecreci/noticias" :disabled="savingData">CANCELAR</v-btn>
    </div>
    <!-- Cropper -->
    <v-dialog
      v-model="dialogCropper"
      width="400"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <div class="d-flex">
          <div>
            <v-card-title>Ajuste a imagem</v-card-title>
            <v-card-subtitle>Arraste a imagem e regule o zoom</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCropper = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="px-1">
          <vue-cropper
            ref="cropper"
            :src="cropperSrc"
            :aspectRatio="cropperMiniaturaMode ? 1 : (1000 / 540)"
            :cropBoxMovable="true"
            :viewMode="1"
            :dragMode="'move'"
          ></vue-cropper>
        </div>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="substituirImagem">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Elementos ocultos -->
    <input
      ref="uploader"
      style="display: none"
      type="file"
      accept="image/jpeg,image/png"
      @change="onFileChange"
    >
    <input
      ref="galery-upload"
      style="display: none"
      type="file"
      accept="image/jpeg,image/png"
      @change="onGaleryInsert"
      multiple
    >
  </async-container>
</template>

<script>
  //ao atualizar a noticia, fazer um load novo nas fotos
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import Editor from '@tinymce/tinymce-vue'
import {loadImageAsync} from '@/plugins/loadImage';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ImageHelper from '@/helpers/ImageHelper';
import moment from 'moment';
export default {
  name: 'NoticiaEditor',
  components: {AsyncContainer, Editor, VueCropper},
  props: ['id'],
  data: () => ({
    loading: true,
    loadingText: '',
    noticia: null,
    iptTitulo: '',
    iptTexto: '',
    iptDataInicio: '',
    iptTimeInicio: '',
    usarRemocao: false,
    iptDataFim: '',
    iptTimeFim: '',
    iptCapa: null, //blob
    iptMiniatura: null, //blob
    iptCropperBlob: null,
    editorConfig: {
      language: 'pt_BR',
      plugins: 'code table link lists fullscreen print preview autoresize image',
      menubar: 'edit view table',
      toolbar: 'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link image | removeformat print', // fontselect
      contextmenu: 'link fullscreen',
      indentation: '2em',
      mobile: {
        theme: 'mobile',
        plugins: ['lists'],
        toolbar: ['undo', 'redo', 'bold', 'italic', 'fontsizeselect', 'underline', 'bullist']
      },
      branding: false,
      images_upload_handler: async (blobInfo, success, failure) => {
        try {
          const blob = blobInfo.blob();
          const canvas = await loadImageAsync(blob, {
            maxWidth: 1280,
            maxHeight: 720,
            canvas: true,
            cover: false,
            orientation: true,
          });
          if (blob.type === 'image/png') success(canvas.toDataURL('image/png'));
          else success(canvas.toDataURL('image/jpeg', .88));
        } catch (e) {
          failure()
        }
      },
      convert_urls: false,
      // relative_urls : false,
      // remove_script_host : false,
    },
    dialogCropper: false,
    dialogDateInicio: false,
    dialogTimeInicio: false,
    dialogDateFim: false,
    dialogTimeFim: false,
    cropperMiniaturaMode: false,
    savingData: false,
    rules: {
      obrigatorio: v => !!v || 'Este campo é obrigatório',
    },
    formsValidation: {
      titulo: false,
      dataInicio: false,
      horaInicio: false,
      dataFim: false,
      horaFim: false,
    },
    galeria: [], //Blob[], novas imagens para a galeria. Inseridas ao clicar em salvar.
    loadingDeleteImgBtn: null, //number = indice da foto atualmente sendo deletada
  }),
  methods: {
    async loadData() {
      if (!this.id) return;
      const webClient = new SiteWebClient();
      const webClientSilent = new SiteWebClient(true);
      this.loading = true;
      try {
        this.loadingText = 'Carregando notícia...';
        const noticia = await webClient.getNoticia(this.id);
        if (noticia.fotos.length > 0) {
          let fotos = noticia.fotos.map(f => webClient.getNoticiaImagem(f));
          this.loadingText = 'Carregando galeria de fotos...';
          fotos = await Promise.all(fotos);
          noticia.fotos = noticia.fotos.map((id, index) => ({ id, blob: fotos[index] }));
        }
        this.noticia = noticia;
        this.iptTitulo = this.noticia.titulo;
        this.iptTexto = this.noticia.artigo;
        this.iptDataInicio = this.noticia.divulgacao.substr(0, 10);
        this.iptTimeInicio = this.noticia.divulgacao.substr(11, 5);
        if (this.noticia.remocao) {
          this.usarRemocao = true;
          this.iptDataFim = this.noticia.remocao.substr(0, 10);
          this.iptTimeFim = this.noticia.remocao.substr(11, 5);
        }
        this.iptCapa = await webClientSilent.getCapa(this.id);
        this.iptMiniatura = await webClientSilent.getMiniatura(this.id);
      } catch (e) {
        this.$router.push('/sitecreci/noticias');
      } finally {
        this.loading = false;
      }
    },
    onFileChange(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      this.iptCropperBlob = new Blob([file], {type: file.type});
      this.cropperMiniaturaMode = false;
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(this.cropperSrc);
        this.$refs.cropper.setAspectRatio(1000 / 540);
      }
      this.dialogCropper = true;
      this.$refs['galery-upload'].value = '';
    },
    async onGaleryInsert(e) {
      if (e.target.files.length && e.target.files.length < 1) return;

      // percorre os arquivos
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const canvas = await loadImageAsync(file, {
          maxWidth: 1280,
          maxHeight: 720,
          canvas: true,
          cover: false,
          orientation: true,
        });
        const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .88);
        const blobCompressed = ImageHelper.dataURItoBlob(base64);
        this.galeria.push(blobCompressed);
      }
      this.$refs.uploader.value = '';
    },
    substituirImagem() {
      if (!this.cropperMiniaturaMode) {
        const canvas = this.$refs.cropper.getCroppedCanvas({
          height: 540,
          width: 1000,
          fillColor: '#ffffff',
        });
        const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .92);
        this.iptCapa = ImageHelper.dataURItoBlob(base64);
        this.$refs.cropper.setAspectRatio(1);
      }

      const canvasMin = this.$refs.cropper.getCroppedCanvas({
        height: 96,
        width: 96,
        fillColor: '#ffffff',
      });
      const base64Min = ImageHelper.canvasToDataURI(canvasMin, 'image/jpeg', .96);
      this.iptMiniatura = ImageHelper.dataURItoBlob(base64Min);

      this.dialogCropper = false;
      this.iptCropperBlob = null;
    },
    evocarCropperMiniatura() {
      this.cropperMiniaturaMode = true;
      this.iptCropperBlob = this.iptCapa;
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(this.cropperSrc);
        this.$refs.cropper.setAspectRatio(1);
      }
      this.dialogCropper = true;
    },
    async salvarNoticia() {
      let valido = true;
      if (!this.formsValidation.titulo) {valido = false; this.$alert({text: 'Titulo inválido', type: 'error'})}
      if (!this.formsValidation.dataInicio || !this.formsValidation.horaInicio) {valido = false; this.$alert({text: 'Arrume a data da publicação', type: 'error'})}
      if (this.usarRemocao && (!this.formsValidation.dataFim || !this.formsValidation.horaFim)) {
        valido = false;
        this.$alert({text: 'Arrume a data da remoção', type: 'error'});
        console.log(this.formsValidation.dataFim, this.formsValidation.horaFim);
      }
      if (!valido) return;

      const webClient = new SiteWebClient();
      this.savingData = true;
      try {
        const datetime_divulgacao = this.iptDataInicio + ' ' + this.iptTimeInicio + ':00';
        const datetime_fim = this.usarRemocao ? (this.iptDataFim + ' ' + this.iptTimeFim + ':00') : null;
        if (this.id) {
          await webClient.updateNoticia(this.id, this.iptTitulo, this.iptTexto, this.iptCapa, this.iptMiniatura, datetime_divulgacao, datetime_fim, this.galeria);
          this.$snackbar({text: 'Notícia atualizada', color: 'success'});
          await this.loadData();
        }
        else {
          await webClient.insertNoticia(this.iptTitulo, this.iptTexto, this.iptCapa, this.iptMiniatura, datetime_divulgacao, datetime_fim, this.galeria);
          this.$snackbar({text: 'Notícia registrada', color: 'success'});
          this.$router.push('/sitecreci/noticias');
        }
      } finally {
        this.savingData = false;
      }
    },
    getBlobTempUrl(blob) {
      return window.URL.createObjectURL(blob);
    },
    async deleteImagem(id) {
      const index = this.noticia.fotos.findIndex(i => i.id === id);
      const siteWebClient = new SiteWebClient();
      this.loadingDeleteImgBtn = index;
      try {
        await siteWebClient.deleteNoticiaImagem(id);
        this.noticia.fotos.splice(index, 1);
      } finally {
        this.loadingDeleteImgBtn = null;
      }
    },
  },
  computed: {
    capaSrc() {
      return this.iptCapa ? window.URL.createObjectURL(this.iptCapa) : null;
    },
    miniaturaSrc() {
      return this.iptMiniatura ? window.URL.createObjectURL(this.iptMiniatura) : null;
    },
    cropperSrc() {
      return this.iptCropperBlob ? window.URL.createObjectURL(this.iptCropperBlob) : null;
    },
  },
  created() {
    if (this.id) this.loadData();
    else {
      this.iptDataInicio = moment().format('YYYY-MM-DD');
      this.iptTimeInicio = moment().format('HH:mm');
      this.loading = false;
    }
  },
}
</script>
