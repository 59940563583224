<template>
  <async-container :loading="loading">
    <v-card class="mb-5">
      <v-card-title style="background-color: #f9f9f9">Convênio</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form-convenio">
          <v-select
            label="Categoria"
            :items="iptCategoriaItems"
            item-text="nome"
            item-value="id"
            v-model="iptCategoria"
            placeholder="Selecione uma categoria"
            prepend-icon="mdi-book-open-page-variant-outline"
            :rules="rules.iptCategoria"
          ></v-select>
          <v-text-field
            label="Empresa"
            placeholder="Coloque o nome da empresa"
            v-model="iptEmpresa"
            prepend-icon="mdi-office-building-marker-outline"
            :rules="rules.iptEmpresa"
          ></v-text-field>
        </v-form>
        <v-menu
          ref="vencimento"
          v-model="showDatepicker"
          :close-on-content-click="false"
          :return-value.sync="iptVencimento"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="iptVencimentoBr"
              label="Data de vencimento"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              placeholder="Deixe em branco se não houver"
              :append-icon="iptVencimento ? 'mdi-close' : undefined"
              @click:append="iptVencimento = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="iptVencimento"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="showDatepicker = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.vencimento.save(iptVencimento)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <p class="subtitle-1 mb-0">Texto</p>
        <p class="caption mb-1">Detalhes, será exibido na página do convênio</p>
        <Editor v-model="iptTexto" :init="editorConfig"/>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title style="background-color: #f9f9f9">Imagem de capa</v-card-title>
      <v-card-subtitle style="background-color: #f9f9f9">A imagem será exibida na página do convênio, logo abaixo do texto</v-card-subtitle>
      <v-divider class="mb-2"></v-divider>
      <v-img :src="iptImagem" max-height="480" contain></v-img>
      <v-card-text>
        <v-file-input
          :label="iptImagem ? 'Trocar imagem' : 'Inserir imagem'"
          v-model="iptImagemTmp"
          accept="image/jpeg"
          outlined
          dense
          persistent-hint
          hint="Resolução recomendada: 1000x540"
        ></v-file-input>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title style="background-color: #f9f9f9">Miniatura</v-card-title>
      <v-card-subtitle style="background-color: #f9f9f9">Imagem pequena, aparece na página que lista todos os convênios</v-card-subtitle>
      <v-divider class="mb-2"></v-divider>
      <v-img :src="iptMiniatura" max-height="96" contain></v-img>
      <v-card-text>
        <v-file-input
          :label="iptMiniatura ? 'Trocar miniatura' : 'Inserir miniatura'"
          v-model="iptMiniaturaTmp"
          accept="image/jpeg"
          outlined
          dense
          persistent-hint
          hint="Resolução recomendada: 96x96"
        ></v-file-input>
      </v-card-text>
    </v-card>
    <v-card-actions class="justify-center my-2">
      <v-btn color="primary" @click="submit" :loading="submiting">Salvar</v-btn>
      <v-btn color="primary" outlined to="/sitecreci/convenios" :disabled="submiting">Sair</v-btn>
    </v-card-actions>
    <v-dialog
      v-model="submiting"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '@/components/interface/AsyncContainer';
  import SiteWebClient from '@/http/SiteWebClient';
  import Editor from '@tinymce/tinymce-vue'
  import {loadImageAsync} from '@/plugins/loadImage';
  import ImageHelper from '@/helpers/ImageHelper';
  import moment from 'moment';
  export default {
    name: 'Convenio',
    components: {AsyncContainer, Editor},
    props: ['id'],
    data: () => ({
      loading: true,
      webClient: new SiteWebClient(),
      editorConfig: {
        language: 'pt_BR',
        plugins: 'code table link lists fullscreen print preview autoresize image',
        menubar: 'edit view table',
        toolbar: 'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link image | removeformat print', // fontselect
        contextmenu: 'link fullscreen',
        indentation: '2em',
        mobile: {
          theme: 'mobile',
          plugins: ['lists'],
          toolbar: ['undo', 'redo', 'bold', 'italic', 'fontsizeselect', 'underline', 'bullist']
        },
        branding: false,
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            const blob = blobInfo.blob();
            const canvas = await loadImageAsync(blob, {
              maxWidth: 1280,
              maxHeight: 720,
              canvas: true,
              cover: false,
              orientation: true,
            });
            if (blob.type === 'image/png') success(canvas.toDataURL('image/png'));
            else success(canvas.toDataURL('image/jpeg', .88));
          } catch (e) {
            failure()
          }
        },
      },
      submiting: false,
      iptCategoria: null,
      iptCategoriaItems: [],
      iptEmpresa: '',
      iptTexto: '',
      iptVencimento: null,
      iptImagem: null,
      iptImagemTmp: null,
      iptMiniatura: null,
      iptMiniaturaTmp: null,
      showDatepicker : false,
      rules: {
        iptCategoria: [v => !!v || 'A categoria é obrigatória'],
        iptEmpresa: [v => !!v || 'O nome da empresa é obrigatório'],
      },
    }),
    methods: {
      async loadData() {
        try {
          if (this.id) {
            const convenio = await this.webClient.getConvenio(this.id);
            this.iptCategoriaItems = convenio.categorias;
            this.iptCategoria = convenio.categoria;
            this.iptEmpresa = convenio.empresa;
            this.iptTexto = convenio.texto;
            this.iptVencimento = convenio.vencimento;
            this.iptMiniatura = convenio.miniatura;
            this.iptImagem = convenio.imagem;
          } else {
            const {categorias} = await this.webClient.getConvenios();
            this.iptCategoriaItems = categorias;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          this.$router.push('/sitecreci/convenios');
        } finally {
          this.loading = false;
        }
      },
      async submit() {
        if (!this.$refs['form-convenio'].validate()) return;
        if (!this.iptImagem) {
          this.$alert({type: 'error', text: 'Insira a imagem de capa', timeout: 2500});
          return;
        }
        if (!this.iptMiniatura) {
          this.$alert({type: 'error', text: 'Insira a imagem de miniatura', timeout: 2500});
          return;
        }
        this.submiting = true;
        try {
          const imagem = ImageHelper.dataURItoFile(this.iptImagem, 'imagem.jpg');
          const miniatura = ImageHelper.dataURItoFile(this.iptMiniatura, 'miniatura.jpg');
          if (this.id) await this.webClient.updateConvenio(this.id, this.iptCategoria, this.iptEmpresa, this.iptTexto, imagem, miniatura, this.iptVencimento);
          else await this.webClient.insertConvenio(this.iptCategoria, this.iptEmpresa, this.iptTexto, imagem, miniatura, this.iptVencimento);
          this.$router.push('/sitecreci/convenios');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.submiting = false;
        }
      },
    },
    created() {
      this.loadData();
    },
    watch: {
      async iptImagemTmp(v) {
        if (v) {
          const canvas = await loadImageAsync(v, {
            maxWidth: 1000,
            maxHeight: 540,
            minWidth: 1000,
            minHeight: 540,
            canvas: true,
            crop: true,
            orientation: true,
          });
          this.iptImagem = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .88);
        }
      },
      async iptMiniaturaTmp(v) {
        if (v) {
          const canvas = await loadImageAsync(v, {
            maxWidth: 96,
            maxHeight: 96,
            minWidth: 96,
            minHeight: 96,
            canvas: true,
            crop: true,
            orientation: true,
          });
          this.iptMiniatura = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .92);
        }
      },
    },
    computed: {
      iptVencimentoBr() {
        return this.iptVencimento ? moment(this.iptVencimento).format('DD/MM/YYYY') : null;
      }
    },
  }
</script>
