var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('async-container',{attrs:{"fluid":"","loading":_vm.loading}},[_c('v-card',[_c('v-card-title',[_vm._v(" Convênios "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","to":"/sitecreci/convenio","color":"primary"}},[_vm._v("Adicionar")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pb-1"},[_c('v-text-field',{attrs:{"label":"Buscar","prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.submiting,"sort-by":"data_modificacao","sort-desc":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.data_modificacao",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatarData(item.data_modificacao)))]}},{key:"item.vencimento",fn:function(ref){
var item = ref.item;
return [(item.vencimento)?[_vm._v(_vm._s(_vm.formatarData(item.vencimento).substr(0, 10)))]:_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("Indefinido")])]}},{key:"item.autor_nome",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.encurtarNome(item.autor_nome)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-btn',{attrs:{"small":"","icon":"","color":"warning","to":'/sitecreci/convenio/' + item.id,"title":"Editar","disabled":_vm.submiting}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"error","title":"Excluir","disabled":_vm.submiting},on:{"click":function($event){return _vm.excluirConvenio(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }