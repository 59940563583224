<template>
  <async-container :loading="firstLoad" style="max-width: 900px">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Banners de destaque</p>
    <v-card class="mb-5 mx-auto">
      <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Pré-visualização</v-card-title>
      <v-carousel height="auto">
        <v-carousel-item v-for="i in items" :key="i.id" :href="i.link ? i.link : undefined" target="_blank">
          <img :src="getBlobTempUrl(i.image)" alt="" style="width: 100%">
          <div class="legenda pb-3">
            <p class="title mb-0">{{i.titulo}}</p>
            <p class="text-truncate">{{i.legenda}}</p>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-card>
    <v-data-table
      class="elevation-2 mb-5"
      :items="items"
      :headers="headers"
      sort-by="ordem"
      :loading="loading"
      :items-per-page="-1"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100, -1]}"
      hide-default-footer
    >
      <template v-slot:top>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">
          Configuração
          <v-spacer/>
          <v-btn color="primary" to="/sitecreci/destaque">ADICIONAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.imagem="{item}">
        <div class="text-center">
          <v-img
            v-if="item.image"
            :src="getBlobTempUrl(item.image)"
            max-width="5rem"
            max-height="3rem"
            style="border-radius: .35rem"
          ></v-img>
        </div>
      </template>
      <template v-slot:item.info="{item}">
        <router-link class="subtitle-1 mb-0 text-decoration-none d-block" :to="'/sitecreci/destaque/' + item.id">Destaque {{item.ordem + 1}}</router-link>
        <v-divider style="max-width: 6rem"/>
        <p class="subtitle-2 mb-0" v-if="item.titulo">{{item.titulo}}</p>
        <a class="caption mb-0 text-decoration-none" v-if="item.link" :href="item.link" target="_blank">
          <v-icon color="primary">mdi-link</v-icon> {{item.link}}
        </a>
      </template>
      <template v-slot:item.ordem="{item}">
        <div class="d-flex flex-nowrap">
          <v-btn icon @click="alterarOrdem(item.id, item.ordem - 1)" :disabled="item.id === primeiroItemId">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn icon @click="alterarOrdem(item.id, item.ordem + 1)" :disabled="item.id === ultimoItemId">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="d-flex flex-nowrap">
          <v-btn icon color="warning" :to="'/sitecreci/destaque/' + item.id">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteDestaque(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-scale-transition>
      <div class="text-center mt-3" v-if="ordemAlterada">
        <v-btn color="primary" @click="updateDestaques" class="mr-2" :disabled="loading">SALVAR</v-btn>
        <v-btn color="secondary" @click="loadData" :disabled="loading">CANCELAR</v-btn>
      </div>
    </v-scale-transition>
    <v-dialog width="500" v-model="showDeleteDialog" persistent>
      <v-card :loading="deleting">
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir destaque</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Tem certeza que deseja apagar o banner?</p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="deleting">CANCELAR</v-btn>
          <v-btn color="error" @click="deleteDestaque()" :loading="deleting">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import SiteWebClient from '@/http/SiteWebClient';
import AsyncContainer from '@/components/interface/AsyncContainer';
export default {
  name: 'Destaques',
  components: {AsyncContainer},
  data: () => ({
    firstLoad: true,
    items: [],
    headers: [
      {value: 'imagem', text: 'IMAGEM', sortable: false},
      {value: 'info', text: 'INFORMAÇÕES', sortable: false},
      {value: 'ordem', text: 'ORDEM', sortable: false},
      {value: 'actions', text: 'AÇÕES', sortable: false},
    ],
    ordemAlterada: false,
    loading: false,
    showDeleteDialog: false,
    deleteDestaqueId: null,
    deleting: false,
  }),
  methods: {
    async loadData() {
      const webClient = new SiteWebClient();
      this.loading = true;
      try {
        let items = await webClient.getDestaques();
        const reqImages = items.map(i => webClient.getDestaqueImage(i.id));
        const images = await Promise.all(reqImages);
        items = items.map((i, index) => {
          i.image = images[index];
          return i;
        });
        this.items = items;
      } finally {
        this.ordemAlterada = false;
        this.loading = false;
        this.firstLoad = false;
      }
    },
    getBlobTempUrl(blob) {
      return URL.createObjectURL(blob);
    },
    alterarOrdem(id, novaOrdem) {
      const items = this.items;
      const indexMigrando = items.findIndex(i => i.id === id);
      const indexSubstituido = items.findIndex(i => i.ordem === novaOrdem);
      items[indexSubstituido].ordem = items[indexMigrando].ordem;
      items[indexMigrando].ordem = novaOrdem;
      this.items = items;
      this.ordemAlterada = true;
    },
    async updateDestaques() {
      const webClient = new SiteWebClient();
      this.loading = true;
      try {
        await webClient.updateDestaquesOrdem(this.items.map(i => ({id: i.id, ordem: i.ordem})));
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    async deleteDestaque(id) {
      if (id) {
        this.deleteDestaqueId = id;
        this.showDeleteDialog = true;
        return;
      }

      const webClient = new SiteWebClient();
      this.deleting = true;
      try {
        await webClient.deleteDestaque(this.deleteDestaqueId);
        await this.loadData();
        this.deleteDestaqueId = null;
        this.showDeleteDialog = false;
      } finally {
        this.deleting = false;
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    primeiroItemId() {
      if (!this.items.length) return 0;
      const x = this.items.reduce((carry, item) => carry.ordem < item.ordem ? carry : item, this.items[0]);
      return x.id;
    },
    ultimoItemId() {
      if (!this.items.length) return 0;
      const x = this.items.reduce((carry, item) => carry.ordem > item.ordem ? carry : item, this.items[0]);
      return x.id;
    },
  },
}
</script>

<style scoped>
  .legenda {
    color: #fff;
    text-shadow: -1px -1px 0 rgba(0,0,0,.3), 1px -1px 0 rgba(0,0,0,.3), -1px 1px 0 rgba(0,0,0,.3), 1px 1px 0 rgba(0,0,0,.3);
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    text-align: center;
  }
</style>
