<template>
  <async-container :loading="loadingContainer">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Comunicados</p>
    <v-data-table
      :items="items"
      :headers="headers"
      sort-by="inicio"
      sort-desc
      class="elevation-2"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title>
          Programação
          <v-spacer/>
          <v-btn color="primary" to="/sitecreci/comunicado">ADICIONAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.ativo="{item}">
        <v-chip small color="error" v-if="item.ativo">NO AR</v-chip>
        <v-chip small color="primary" v-else-if="item.inicio > now">NA FILA</v-chip>
        <v-chip small color="secondary" v-else>EXPIRADO</v-chip>
      </template>
      <template v-slot:item.inicio="{item}">{{formatarData(item.inicio)}}</template>
      <template v-slot:item.fim="{item}">{{formatarData(item.fim)}}</template>
      <template v-slot:item.actions="{item}">
        <div class="d-flex flex-nowrap">
          <v-btn icon x-small :to="'/sitecreci/comunicado/' + item.id" color="warning">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small @click="deletarComunicado(item.id, item.titulo)" color="error">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="showDeleteDialog" persistent>
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir comunicado</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a apagar:<br/><span class="font-weight-bold">"{{deleteTitle}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="loading">CANCELAR</v-btn>
          <v-btn color="error" @click="deletarComunicado()" :loading="loading">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import moment from 'moment';
export default {
  name: 'Comunicados',
  components: {AsyncContainer},
  data: () => ({
    loadingContainer: true,
    items: [],
    headers: [
      {value: 'id', text: 'ID'},
      {value: 'titulo', text: 'TITULO'},
      {value: 'ativo', text: 'STATUS', sortable: false},
      {value: 'inicio', text: 'DATA INICIO'},
      {value: 'fim', text: 'DATA FIM'},
      {value: 'actions', text: 'AÇÕES', sortable: false},
    ],
    now: moment().format('YYYY-MM-DD HH:mm:ss'),
    showDeleteDialog: false,
    deleteId: null,
    deleteTitle: '',
    loading: false,
  }),
  methods: {
    async loadData() {
      const webClient = new SiteWebClient();
      try {
        this.items = await webClient.getComunicados();
      } catch (e) {
        this.$router.push('/sitecreci');
      } finally {
        this.loadingContainer = false;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    async deletarComunicado(id = null, titulo = null) {
      if (id === null && titulo === null && this.deleteId) {
        const webClient = new SiteWebClient();
        this.loading = true;
        try {
          await webClient.deleteComunicado(this.deleteId);
          await this.loadData();
        } finally {
          this.loading = false;
          this.showDeleteDialog = false;
        }
      } else {
        this.deleteId = id;
        this.deleteTitle = titulo;
        this.showDeleteDialog = true;
      }
    },
  },
  created() {
    this.loadData();
  },
}
</script>
