<template>
  <async-container fluid :loading="!loaded">
    <v-alert type="info">
      Os eventos são removidos do site do CRECI 90 minutos após a data/hora de seu início.
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="id"
      sort-desc
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-card-title>
          Eventos
          <v-spacer/>
          <v-btn color="primary" to="/sitecreci/evento">CRIAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.data_inicio="{item}">{{formatarData(item.data_inicio)}}</template>
      <template v-slot:item.inscricoes="{item}">
        <v-chip x-small color="secondary" label v-if="item.vagas === null">Não utiliza</v-chip>
        <span v-else>{{item.inscricoes}}</span>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="text-no-wrap">
          <v-btn icon x-small :to="'/sitecreci/evento/' + item.id" color="warning">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small color="info" :disabled="item.vagas === null" :to="'/sitecreci/evento/inscricoes/' + item.id">
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
          <v-btn icon x-small color="error" @click="deletarEvento(item.id, item.titulo)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="showDeleteDialog" persistent>
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir evento</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a apagar:<br/><span class="font-weight-bold">"{{deleteTitle}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="deleting">CANCELAR</v-btn>
          <v-btn color="error" @click="deletarEvento()" :loading="deleting">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import moment from 'moment';
export default {
  name: 'Eventos',
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    webClient: new SiteWebClient(),
    headers: [
      {value: 'id', text: 'ID'},
      {value: 'titulo', text: 'TITULO'},
      {value: 'data_inicio', text: 'DATA', align: 'center'},
      {value: 'inscricoes', text: 'INSCRIÇÕES', align: 'center'},
      {value: 'actions', text: 'AÇÕES', align: 'center', sortable: false},
    ],
    items: [],
    deleteId: null,
    deleteTitle: '',
    deleting: false,
    showDeleteDialog: false,
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getEventos();
      } catch (e) {
        this.$router.push('/sitecreci');
      } finally {
        this.loaded = true;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    async deletarEvento(id = null, titulo = '') {
      if (!id && !titulo && this.deleteId) {
        this.deleting = true;
        try {
          await this.webClient.deleteEvento(this.deleteId);
          await this.loadData();
        } finally {
          this.deleting = false;
          this.showDeleteDialog = false;
        }
      } else {
        this.deleteId = id;
        this.deleteTitle = titulo;
        this.showDeleteDialog = true;
      }
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>