import { render, staticRenderFns } from "./Eventos.vue?vue&type=template&id=553f80f3&scoped=true&"
import script from "./Eventos.vue?vue&type=script&lang=js&"
export * from "./Eventos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553f80f3",
  null
  
)

export default component.exports