<template>
  <async-container :loading="firstLoading" fluid>
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Notícias</p>
    <v-data-table
      class="elevation-2"
      :items="noticias"
      :headers="headers"
      sort-by="divulgacao"
      sort-desc
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Publicações</v-toolbar-title>
          <v-spacer/>
          <v-btn color="primary" to="/sitecreci/noticia">CRIAR</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.titulo="{item}">
        <a class="text-decoration-none" :href="'http://crecims.gov.br/noticia.php?id=' + item.id" target="_blank">{{item.titulo}}</a>
      </template>
      <template v-slot:item.divulgacao="{item}">{{formatarData(item.divulgacao)}}</template>
      <template v-slot:item.actions="{item}">
        <div class="d-flex flex-nowrap">
          <v-btn icon x-small :to="'/sitecreci/noticia/' + item.id" color="warning" title="Editar">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small @click="deletarNoticia(item.id, item.titulo)" color="error" title="Apagar">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="showDeleteDialog" persistent>
      <v-card :loading="loading">
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir notícia</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a apagar a notícia:<br/><span class="font-weight-bold">"{{deleteNoticiaTitulo}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="loading">CANCELAR</v-btn>
          <v-btn color="error" @click="deletarNoticia()" :loading="loading">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import moment from 'moment';
export default {
  name: 'Noticias',
  components: {AsyncContainer},
  data: () => ({
    firstLoading: true,
    loading: false,
    noticias: [],
    headers: [
      {value: 'id', text: 'ID'},
      {value: 'titulo', text: 'TITULO'},
      {value: 'divulgacao', text: 'DATA DE PUBLICAÇÃO'},
      {value: 'visualizacoes', text: 'VIEWS'},
      {value: 'visitantes', text: 'VISITANTES'},
      {value: 'actions', text: 'AÇÕES', sortable: false},
    ],
    showDeleteDialog: false,
    deleteNoticiaId: null,
    deleteNoticiaTitulo: '',
  }),
  methods: {
    async loadData() {
      const webClient = new SiteWebClient();
      try {
        this.noticias = await webClient.getNoticias();
      } finally {
        this.firstLoading = false;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    async deletarNoticia(id = null, titulo = null) {
      if (id === null && titulo === null && this.deleteNoticiaId) {
        const webClient = new SiteWebClient();
        this.loading = true;
        try {
          await webClient.deleteNoticia(this.deleteNoticiaId);
          await this.loadData();
        } finally {
          this.loading = false;
          this.showDeleteDialog = false;
        }
      } else {
        this.deleteNoticiaId = id;
        this.deleteNoticiaTitulo = titulo;
        this.showDeleteDialog = true;
      }
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>
