<template>
  <async-container :loading="loadingContainer">
    <p class="caption mb-0">Site do CRECI</p>
    <p class="title">Banner de destaque</p>
    <!-- Imagem -->
    <v-card max-width="750" class="mx-auto">
      <v-card-title style="border-bottom: 1px solid #dbdbdb">
        Imagem
        <v-spacer/>
        <v-btn v-if="iptImagem" color="primary" @click="$refs.uploader.click()">ALTERAR</v-btn>
      </v-card-title>
      <div v-if="imagemSrc">
        <img :src="imagemSrc" alt="" style="max-width: 100%"/>
        <div class="legenda pb-3">
          <p class="title mb-0">{{iptTitulo}}</p>
          <p class="text-truncate">{{iptLegenda}}</p>
        </div>
      </div>
      <div v-else class="pa-3 text-center">
        <p class="caption mb-1">Insira a imagem do banner</p>
        <v-btn color="primary" @click="$refs.uploader.click()">INSERIR IMAGEM</v-btn>
      </div>
    </v-card>
    <!-- Configurações -->
    <v-card max-width="750" class="mx-auto mt-5">
      <v-card-title>Ajustes</v-card-title>
      <v-card-text>
        <v-text-field v-model="iptLink" label="Link" hint="Opcional. O link torná a imagem clicável" persistent-hint></v-text-field>
        <v-text-field v-model="iptTitulo" label="Titulo" hint="Opcional. Exibirá o título sobre a imagem" persistent-hint></v-text-field>
        <v-text-field v-model="iptLegenda" label="Legenda" hint="Opcional. Descrição do banner, será exibida abaixo do título" persistent-hint></v-text-field>
      </v-card-text>
    </v-card>
    <!-- Botões de ação -->
    <div class="my-3 text-center">
      <v-btn color="primary" class="mr-2" @click="salvarDestaque" :loading="saving">SALVAR</v-btn>
      <v-btn color="secondary" to="/sitecreci/destaques" :disabled="saving">CANCELAR</v-btn>
    </div>
    <!-- Cropper -->
    <v-dialog
        v-model="dialogCropper"
        width="400"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        persistent
    >
      <v-card>
        <div class="d-flex">
          <div>
            <v-card-title>Ajuste a imagem</v-card-title>
            <v-card-subtitle>Arraste a imagem e regule o zoom</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCropper = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="px-2">
          <vue-cropper
              ref="cropper"
              :src="cropperSrc"
              :aspectRatio="750/405"
              :cropBoxMovable="true"
              :viewMode="1"
              :dragMode="'move'"
          ></vue-cropper>
        </div>
        <v-card-actions class="justify-center">
          <v-btn color="primary" @click="substituirImagem">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
        ref="uploader"
        style="display: none"
        type="file"
        accept="image/jpeg,image/png"
        @change="onFileChange"
    >
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ImageHelper from '@/helpers/ImageHelper';
export default {
  name: 'DestaqueEditor',
  props: ['id'],
  components: {AsyncContainer, VueCropper},
  data: () => ({
    loadingContainer: true,
    iptLink: '',
    iptTitulo: '',
    iptLegenda: '',
    iptImagem: '',
    iptCropperBlob: null,
    dialogCropper: false,
    saving: false,
  }),
  methods: {
    async loadData() {
      if (!this.id) return;
      const webClient = new SiteWebClient();
      try {
        const destaque = await webClient.getDestaque(parseInt(this.id));
        this.iptTitulo = destaque.titulo ? destaque.titulo : '';
        this.iptLink = destaque.link ? destaque.link : '';
        this.iptLegenda = destaque.legenda ? destaque.legenda : '';
        this.iptImagem = destaque.imagem;
      } catch (e) {
        this.$router.push('/sitecreci/destaques');
      } finally {
        this.loadingContainer = false;
      }
    },
    onFileChange(e) {
      if (e.target.files.length !== 1) return;
      const file = e.target.files[0];
      this.iptCropperBlob = new Blob([file], {type: file.type});
      if (this.$refs.cropper) this.$refs.cropper.replace(this.cropperSrc);
      this.dialogCropper = true;
      this.$refs.uploader.value = '';
    },
    substituirImagem() {
      const canvas = this.$refs.cropper.getCroppedCanvas({
        height: 405,
        width: 750,
        fillColor: '#ffffff',
      });
      const base64 = ImageHelper.canvasToDataURI(canvas, 'image/jpeg', .88);
      this.iptImagem = ImageHelper.dataURItoBlob(base64);
      this.dialogCropper = false;
      this.iptCropperBlob = null;
    },
    async salvarDestaque() {
      const webClient = new SiteWebClient();
      this.saving = true;
      try {
        if (this.id) {
          await webClient.updateDestaque(this.id, this.iptImagem, this.iptLink.trim(), this.iptTitulo.trim(), this.iptLegenda.trim());
          this.$snackbar({text: 'Banner de destaque atualizado', color: 'success'});
        }
        else {
          await webClient.insertDestaque(this.iptImagem, this.iptLink.trim(), this.iptTitulo.trim(), this.iptLegenda.trim());
          this.$snackbar({text: 'Banner de destaque registrado', color: 'success'});
          this.$router.push('/sitecreci/destaques');
        }
      } finally {
        this.saving = false;
      }
    },
  },
  computed: {
    imagemSrc() {
      return this.iptImagem ? window.URL.createObjectURL(this.iptImagem) : null;
    },
    cropperSrc() {
      return this.iptCropperBlob ? window.URL.createObjectURL(this.iptCropperBlob) : null;
    },
  },
  created() {
    if (this.id) this.loadData();
    else this.loadingContainer = false;
  },
}
</script>

<style scoped>
  .legenda {
    color: #fff;
    text-shadow: -1px -1px 0 rgba(0,0,0,.3), 1px -1px 0 rgba(0,0,0,.3), -1px 1px 0 rgba(0,0,0,.3), 1px 1px 0 rgba(0,0,0,.3);
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    text-align: center;
  }
</style>
