<template>
  <async-container fluid :loading="loading">
    <v-card>
      <v-card-title>
        Convênios
        <v-spacer></v-spacer>
        <v-btn small to="/sitecreci/convenio" color="primary">Adicionar</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-1">
        <v-text-field
          v-model="search"
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          dense
          outlined
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :loading="submiting"
        sort-by="data_modificacao"
        sort-desc
        :search="search"
      >
        <template v-slot:item.data_modificacao="{item}">{{formatarData(item.data_modificacao)}}</template>
        <template v-slot:item.vencimento="{item}">
          <template v-if="item.vencimento">{{formatarData(item.vencimento).substr(0, 10)}}</template>
          <v-chip v-else label small>Indefinido</v-chip>
        </template>
        <template v-slot:item.autor_nome="{item}">{{encurtarNome(item.autor_nome)}}</template>
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap">
            <v-btn
              small
              icon
              color="warning"
              :to="'/sitecreci/convenio/' + item.id"
              title="Editar"
              :disabled="submiting"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              color="error"
              @click="excluirConvenio(item)"
              title="Excluir"
              :disabled="submiting"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </async-container>
</template>

<script>
  import AsyncContainer from '@/components/interface/AsyncContainer';
  import SiteWebClient from '@/http/SiteWebClient';
  import moment from 'moment';
  import StringHelper from "@/helpers/StringHelper";
  export default {
    name: 'Convenios',
    components: {AsyncContainer},
    data: () => ({
      loading: true,
      webClient: new SiteWebClient(),
      items: [],
      headers: [
        {value: 'data_modificacao', text: 'Cadastrado em'},
        {value: 'empresa', text: 'Empresa'},
        {value: 'categoria_nome', text: 'Categoria'},
        {value: 'vencimento', text: 'Vencimento'},
        {value: 'autor_nome', text: 'Cadastrado por'},
        {value: 'actions', text: 'Ações', filterable: false},
      ],
      submiting: false,
      search: '',
    }),
    methods: {
      async loadData() {
        try {
          const {convenios} = await this.webClient.getConvenios();
          this.items = convenios;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          this.$router.push('/');
        } finally {
          this.loading = false;
        }
      },
      formatarData(datetime) {
        return moment(datetime).format('DD/MM/YYYY HH:mm');
      },
      encurtarNome(nome) {
        return StringHelper.shortName(nome, true);
      },
      async excluirConvenio(item) {
        if (!confirm('Tem certeza que deseja apagar este convênio da empresa "' + item.empresa + '"?')) return;
        this.submiting = true;
        try {
          await this.webClient.deleteConvenio(item.id);
          await this.loadData();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.submiting = false;
        }
      },
    },
    created() {
      this.loadData();
    },
  }
</script>

<style scoped>

</style>