<template>
  <v-container>
    <p class="title mb-0">Site do CRECI</p>
    <p class="subtitle-1">Ferramentas para gerenciar o site do conselho</p>
    <v-divider class="mb-5"/>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/destaques">
        <v-list-item-icon>
          <v-icon>mdi-image-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Banners de destaque</v-list-item-title>
          <v-list-item-subtitle>Slide de banners apresentados na primeira tela do site</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/convenios">
        <v-list-item-icon>
          <v-icon>mdi-handshake</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Convênios</v-list-item-title>
          <v-list-item-subtitle>Gerencie os convênios divulgados no site</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/comunicados">
        <v-list-item-icon>
          <v-icon>mdi-bullhorn</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Comunicado</v-list-item-title>
          <v-list-item-subtitle>Programe um comunicado importante para surgir na primeira página do site</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/eventos">
        <v-list-item-icon>
          <v-icon>mdi-calendar-star</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Eventos</v-list-item-title>
          <v-list-item-subtitle>Divulgue eventos no site, com ou sem sistema de inscrição</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/geradorlink">
        <v-list-item-icon>
          <v-icon>mdi-cloud-download</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Gerador de links para download</v-list-item-title>
          <v-list-item-subtitle>Gere links para baixar arquivos, hospedando no site do CRECI</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/noticias">
        <v-list-item-icon>
          <v-icon>mdi-newspaper</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Notícias</v-list-item-title>
          <v-list-item-subtitle>Gerencie as publicações do site</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/palavrapresidente">
        <v-list-item-icon>
          <v-icon>mdi-face-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Palavra do Presidente</v-list-item-title>
          <v-list-item-subtitle>Edite o texto institucional do presidente</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/sitecreci/textoinstitucional">
        <v-list-item-icon>
          <v-icon>mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Texto Institucional</v-list-item-title>
          <v-list-item-subtitle>Edite o texto institucional do CRECI</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'Index'
}
</script>

<style scoped>

</style>