<template>
  <async-container :loading="!loaded" fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-2"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
      sort-by="id"
      sort-desc
      no-data-text="Nenhum link nos registros"
    >
      <template v-slot:top>
        <v-card-title>
          Gerador de links
          <v-spacer/>
          <v-btn color="success" small @click="showAddLink = true">Adicionar</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.size="{item}">{{getMegabytes(item.size)}} MB</template>
      <template v-slot:item.datahora="{item}">{{formatarData(item.datahora)}}</template>
      <template v-slot:item.actions="{item}">
        <div class="d-flex flex-nowrap">
          <v-btn icon small color="primary" @click="downloadFile(item.id, item.nome)" :loading="downloadingId === item.id">
            <v-icon>mdi-download-circle</v-icon>
          </v-btn>
          <v-btn icon small color="error" @click="evocarDialogDelete(item.id, item.nome)">
            <v-icon>mdi-delete-circle</v-icon>
          </v-btn>
          <v-btn icon small color="info" @click="copyUrl(item.id)">
            <v-icon>mdi-link-variant</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- Dialog: Add Link -->
    <v-dialog v-model="showAddLink" width="400" max-width="94%">
      <v-card>
        <v-card-title>Novo link</v-card-title>
        <v-card-text class="mt-3">
          <v-form v-model="formValid" ref="form-file">
            <v-file-input
              label="Arquivo para upload"
              outlined
              :hint="iptFile ? '' : 'O limite para upload é de até 10MB'"
              persistent-hint
              :rules="rules.file"
              v-model="iptFile"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined color="secondary" :disabled="uploading" @click="showAddLink = false">Cancelar</v-btn>
          <v-btn color="primary" :disabled="!formValid" :loading="uploading" @click="sendFile">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog: Delete File -->
    <v-dialog v-model="showDeleteDialog" width="400" max-width="94%" persistent>
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir link</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a apagar:<br/><span class="font-weight-bold">"{{deleteTitle}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="deleting">CANCELAR</v-btn>
          <v-btn color="error" @click="deleteFile" :loading="deleting">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import SiteWebClient from '@/http/SiteWebClient';
import AsyncContainer from '@/components/interface/AsyncContainer';
import moment from "moment";
export default {
  name: 'GeradorLink',
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Nome', value: 'nome'},
      {text: 'Tamanho', value: 'size'},
      {text: 'Data upload', value: 'datahora'},
      {text: 'Autor', value: 'autor_nome'},
      {text: 'Ações', value: 'actions'},
    ],
    items: [],
    showAddLink: false,
    rules: {
      file: [
        v => !!v || 'Anexe um arquivo',
        v => !v || v.size < 10000000 || 'O arquivo precisa ser menor que 10MB!'
      ],
    },
    iptFile: null,
    formValid: false,
    uploading: false,
    downloadingId: null, //number - ID do arquivo sendo baixado
    deletingId: null, //number - ID do arquivo sendo deletado
    deleteTitle: '',
    showDeleteDialog: false,
    deleting: false,
  }),
  methods: {
    async loadData() {
      const siteWebClient = new SiteWebClient();
      try {
        this.items = await siteWebClient.getLinksDownload();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        this.$router.push('/sitecreci');
      } finally {
        this.loaded = true;
      }
    },
    async sendFile() {
      if (!this.$refs['form-file'].validate() || !this.iptFile) return;
      const siteWebClient = new SiteWebClient();
      this.uploading = true;
      try {
        await siteWebClient.insertLinkDownload(this.iptFile);
        await this.loadData();
        this.showAddLink = false;
        this.$refs['form-file'].reset();
        await this.$nextTick();
        if (this.iptFile) this.iptFile = null;
      } finally {
        this.uploading = false;
      }
    },
    async downloadFile(id, filename) {
      const siteWebClient = new SiteWebClient();
      this.downloadingId = id;
      try {
        const file = await siteWebClient.getLinkDownloadFile(id);
        const element = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        element.href = url;
        element.download = filename;
        element.click();
        window.URL.revokeObjectURL(url);
      } finally {
        this.downloadingId = null;
      }
    },
    async deleteFile() {
      if (!this.deletingId) return;
      const siteWebClient = new SiteWebClient();
      this.deleting = true;
      try {
        await siteWebClient.deleteLinksDownload(this.deletingId);
        await this.loadData();
        this.showDeleteDialog = false;
        this.$snackbar({text: 'Link removido', color: 'success'})
      } finally {
        this.deleting = false;
        this.deletingId = null;
      }
    },
    evocarDialogDelete(id, filename) {
      this.deletingId = id;
      this.deleteTitle = filename;
      this.showDeleteDialog = true;
    },
    copyUrl(id) {
      const siteWebClient = new SiteWebClient();
      const url = siteWebClient.getLinkDownloadUrl(id);
      const el = window.document.createElement('textarea');

      el.value = url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      window.document.body.appendChild(el);
      el.select();
      window.document.execCommand('copy');
      window.document.body.removeChild(el);
      this.$snackbar({text: 'Link copiado', color: 'info'})
    },
    getMegabytes(bytes) {
      return ((bytes/1024)/1024).toFixed(bytes > 10486 ? 2 : 3);
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>