<template>
  <async-container fluid :loading="!loaded">
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="nome"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
      class="elevation-2"
    >
      <template v-slot:item.instante="{item}">{{formatarData(item.instante)}}</template>
      <template v-slot:item.creci="{item}">
        <v-chip x-small color="secondary" label v-if="!item.creci">NÃO INFORMADO</v-chip>
        <span v-else>{{item.creci}}</span>
      </template>
      <template v-slot:item.status="{item}">
        <v-btn
          v-if="item.status === 0"
          small
          depressed
          color="error"
          :loading="loadingPresence === item.id"
          :disabled="loadingPresence !== false && loadingPresence !== item.id"
          @click="alterarStatus(item.id, true)"
        >FALTA</v-btn>
        <v-btn
          v-else-if="item.status === 1"
          small
          depressed
          color="success"
          :loading="loadingPresence === item.id"
          :disabled="loadingPresence !== false && loadingPresence !== item.id"
          @click="alterarStatus(item.id, false)"
        >PRESENTE</v-btn>
      </template>
    </v-data-table>
    <div class="text-center my-3">
      <v-btn color="primary" to="/sitecreci/eventos">VOLTAR</v-btn>
    </div>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SiteWebClient from '@/http/SiteWebClient';
import moment from "moment";
export default {
  name: 'EventoInscricoes',
  components: {AsyncContainer},
  props: ['evento'],
  data: () => ({
    loaded: false,
    webClient: new SiteWebClient(),
    headers: [
      {value: 'nome', text: 'NOME'},
      {value: 'instante', text: 'Inscrito em', align: 'center'},
      {value: 'cpf', text: 'CPF', align: 'center'},
      {value: 'creci', text: 'CRECI', align: 'center'},
      {value: 'email', text: 'EMAIL'},
      {value: 'fone', text: 'TELEFONE'},
      {value: 'status', text: 'STATUS', align: 'center'},
    ],
    items: [],
    loadingPresence: false,
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getEventoInscricoes(this.evento);
      } catch (e) {
        this.$router.push('/sitecreci/eventos');
      } finally {
        this.loaded = true;
      }
    },
    async alterarStatus(id, presente = true) {
      this.loadingPresence = id;
      try {
        await this.webClient.updateEventoInscricaoPresenca(id, presente);
        await this.loadData();
      } finally {
        this.loadingPresence = false;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
  },
  created() {
    this.loadData();
  },
}
</script>
